const goToPage = (pageSelector) => {
  const subPageToFadeOut = document.querySelector(".login-subpage.active");
  subPageToFadeOut.classList.add("fadingOut");

  const subPageToFadeIn = document.querySelector(pageSelector);
  subPageToFadeIn.classList.add("fadingIn");

  setTimeout(() => {
    subPageToFadeIn.classList.remove("fadingIn");
    subPageToFadeIn.classList.add("active");
    subPageToFadeOut.classList.remove("active");
    subPageToFadeOut.classList.remove("fadingOut");
  }, 200);
}

const showError = (error) => {
  document.getElementById("LblError").innerHTML = error;
  document.getElementById("PnlError").style.display = "flex";
}

const showSuccess = (message) => {
  document.getElementById("LblSuccess").innerHTML = message;
  document.getElementById("PnlSuccess").style.display = "flex";
}

let email;
const sendChangePassCode = () => {
  softLoad();
  email = document.getElementById("TxtForgottenPassEmail").value;
  const data = {"email": email};
  fetch("/Pages/Login/Login.aspx/SendResetPasswordRequest", {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => {
    res.json().then(response => {
      stopLoading();
      if (response.d === "OK") {
        goToPage("#NewPassSubpage");
      } else {
        showError("E-mail non riconosciuta.");
      }
    });
  }).catch(() => {
    stopLoading();
    showError("Si è verificato un errore durante l'invio della richiesta");
  })
}

const changePass = () => {
  softLoad();

  const newPass = document.getElementById("TxtNewPass").value;
  const repeatNewPass = document.getElementById("TxtRepeatNewPass").value;

  if (newPass !== repeatNewPass) {
    stopLoading()
    showError("Le password non coincidono.");
    return;
  }

  const code = document.getElementById("TxtConfirmationCode").value;
  const data = {
    "email": email,
    "newPass": newPass,
    "code": code
  };

  fetch("/Pages/Login/Login.aspx/ChangePassword", {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => {
    res.json().then(response => {
      stopLoading();
      if (response.d === "OK") {
        showSuccess("Password modificata con successo!");
      } else {
        showError("Codice di conferma non corretto.");
      }
    });
  }).catch(() => {
    stopLoading();
    showError("Si è verificato un errore durante l'invio della richiesta");
  });
}

document.addEventListener("DOMContentLoaded", () => {
  [...document.querySelectorAll(".login-button-expander > a")].forEach(el => {
    el.addEventListener("click", () => {
      const box = el.nextElementSibling;
      [...document.querySelectorAll(".login-button-remember-box")].forEach(ell => {
        if (!box.isSameNode(ell)) ell.classList.add("collapsed");
      });
      box.classList.toggle("collapsed");
      return false;
    });
  });
});